import { Component, OnInit } from '@angular/core';
import { Manhwa } from './manhwa';
import { HttpClient } from '@angular/common/http';
import { Series } from './series';
import { Anime } from './anime';
import { Media } from './media';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

  manhwas?:Array<Manhwa>;
  series?:Array<Series>;
  animes?:Array<Anime>;
  filteredManhwa:Array<Manhwa> = new Array<Manhwa>();
  filteredSeries:Array<Series> = new Array<Series>();
  filteredAnime:Array<Anime> = new Array<Anime>();
  chapters:number = 0;
  needsSeason:boolean = false;
  isFiltered:boolean = false;

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.loadMedias();
  }

  loadMedias(){
    this.loadManhwas();
    this.loadAnimes();
    this.loadSeries();
  }

  loadAnimes(){
    this.http.get("https://nicolasovic.ch/apiMedia/anime").subscribe(
      data => {
        this.animes = data as Array<Anime>;
        this.formatTimestamp("anime");
        if(this.isSearching){
          this.searchMedia();
        }
      }
    )
  }

  loadSeries(){
    this.http.get("https://nicolasovic.ch/apiMedia/serie").subscribe(
      data => {
        this.series = data as Array<Series>;
        this.formatTimestamp("series");
        if(this.isSearching){
          this.searchMedia();
        }
      }
    )
  }

  loadManhwas(){
    this.http.get("https://nicolasovic.ch/apiMedia/manhwa").subscribe(
      data => {
        this.manhwas = data as Array<Manhwa>;
        this.formatTimestamp("manhwa");
        if(this.isSearching){
          this.searchMedia();
        }
      }
    )
  }

  formatTimestamp(type){
    var year;
    var month;
    var day;
    var hour;
    var minute;
    var second;
    var datePart:string = "";
    var timePart:string = "";
    switch(type){
      case "anime":
        for(var anime of this.animes){
          datePart = anime.lastUpdated.split("T")[0];
          timePart = anime.lastUpdated.split("T")[1];
          year = datePart.split("-")[0];
          month = datePart.split("-")[1];
          day = datePart.split("-")[2];
    
          hour = timePart.split(":")[0];
          minute = timePart.split(":")[1];
          second = timePart.split(":")[2].split(".")[0];
    
          anime.lastUpdated = hour + ":" + minute + ":" + second + " " + day + "." + month + "." + year;
        }
        break;
      case "series":
        for(var serie of this.series){
          datePart = serie.lastUpdated.split("T")[0];
          timePart = serie.lastUpdated.split("T")[1];
          year = datePart.split("-")[0];
          month = datePart.split("-")[1];
          day = datePart.split("-")[2];
    
          hour = timePart.split(":")[0];
          minute = timePart.split(":")[1];
          second = timePart.split(":")[2].split(".")[0];
    
          serie.lastUpdated = hour + ":" + minute + ":" + second + " " + day + "." + month + "." + year;
        }
        break;
      case "manwha":
        for(var manhwa of this.manhwas){
          datePart = manhwa.lastUpdated.split("T")[0];
          timePart = manhwa.lastUpdated.split("T")[1];
          year = datePart.split("-")[0];
          month = datePart.split("-")[1];
          day = datePart.split("-")[2];
    
          hour = timePart.split(":")[0];
          minute = timePart.split(":")[1];
          second = timePart.split(":")[2].split(".")[0];
    
          manhwa.lastUpdated = hour + ":" + minute + ":" + second + " " + day + "." + month + "." + year;
        }
        break;
    }
  }

  addMedia(){
    var name = document.getElementById("txtName") as HTMLInputElement;
    var link = document.getElementById("txtLink") as HTMLInputElement;
    var chapter = document.getElementById("numChapter") as HTMLInputElement;
    var season = document.getElementById("numSeason") as HTMLInputElement;
    var media = document.getElementById("selectMedia") as HTMLSelectElement;
    var key = document.getElementById("txtKey") as HTMLInputElement;

    var values;

    if(season == null){
      values = {name: name.value, chapter: chapter.valueAsNumber, url: link.value, key: key.value, season: 0};
    }
    else{
      values = {name: name.value, chapter: chapter.valueAsNumber, url: link.value, key: key.value, season: season.value};
    }

    this.http.post("https://nicolasovic.ch/apiMedia/" + media.value, values).subscribe(
      data => {
        this.loadMedias();
      },
      error => {
        console.log(error);
      }
    )
  }

  changeCount(manhwa: Manhwa, change: number){
    var key = document.getElementById("txtKey") as HTMLInputElement;
    var count = document.getElementById("inCount" + manhwa.id) as HTMLInputElement;
    if(count.value.toString().toLowerCase() != "e" && Number.parseInt(count.value) > 0){
      var newCount = manhwa.chapter + (change * Number.parseInt(count.value));
      this.http.put("https://nicolasovic.ch/apiMedia/manhwa/" + manhwa.id, {url: manhwa.link, chapter: newCount, key: key.value}).subscribe(
        data => {
          this.loadManhwas();
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  changeEpisodeAnime(anime:Anime, change: number){
    var key = document.getElementById("txtKey") as HTMLInputElement;
    var count = document.getElementById("inEpisodeAnime" + anime.id) as HTMLInputElement;
    if(count.value.toString().toLowerCase() != "e" && Number.parseInt(count.value) > 0){
      var newCount = anime.episode + (change * Number.parseInt(count.value));
      this.http.put("https://nicolasovic.ch/apiMedia/anime/" + anime.id, {season: anime.season, chapter: newCount, key: key.value}).subscribe(
        data => {
          this.loadAnimes();
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  changeEpisodeSeries(series:Series, change: number){
    var key = document.getElementById("txtKey") as HTMLInputElement;
    var count = document.getElementById("inEpisodeSeries" + series.id) as HTMLInputElement;
    if(count.value.toString().toLowerCase() != "e" && Number.parseInt(count.value) > 0){
      var newCount = series.episode + (change * Number.parseInt(count.value));
      this.http.put("https://nicolasovic.ch/apiMedia/serie/" + series.id, {season: series.season, chapter: newCount, key: key.value}).subscribe(
        data => {
          this.loadSeries();
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  changeSeasonAnime(anime: Anime, change: number){
    var key = document.getElementById("txtKey") as HTMLInputElement;
    var count = document.getElementById("inSeasonAnime" + anime.id) as HTMLInputElement;
    if(count.value.toString().toLowerCase() != "e" && Number.parseInt(count.value) > 0){
      var newCount = anime.season + (change * Number.parseInt(count.value));
      this.http.put("https://nicolasovic.ch/apiMedia/anime/" + anime.id, {season: newCount, chapter: anime.episode, key: key.value}).subscribe(
        data => {
          this.loadAnimes();
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  changeSeasonSeries(series: Series, change: number){
    var key = document.getElementById("txtKey") as HTMLInputElement;
    var count = document.getElementById("inSeasonSeries" + series.id) as HTMLInputElement;
    if(count.value.toString().toLowerCase() != "e" && Number.parseInt(count.value) > 0){
      var newCount = series.season + (change * Number.parseInt(count.value));
      this.http.put("https://nicolasovic.ch/apiMedia/serie/" + series.id, {season: newCount, chapter: series.episode, key: key.value}).subscribe(
        data => {
          this.loadSeries();
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  editLink(manhwa:Manhwa){
    var edit = document.getElementById("btnEdit" + manhwa.id) as HTMLButtonElement;
    var link = document.getElementById("linkManhwa" + manhwa.id) as HTMLLinkElement;
    var div = document.getElementById("divManhwa" + manhwa.id) as HTMLDivElement;
    var key = document.getElementById("txtKey") as HTMLInputElement;
    if(edit.textContent.toLowerCase() == "edit"){
      var input = document.createElement("input") as HTMLInputElement;
      input.value = link.href;
      input.id = "inLink" + manhwa.id;
      div.appendChild(input);
      edit.textContent = "Save";
      link.style.visibility = "hidden";
    }
    else{
      var inLink = document.getElementById("inLink" + manhwa.id) as HTMLInputElement;
      link.style.visibility = "";
      edit.textContent = "Edit";
      this.http.put("https://nicolasovic.ch/apiMedia/manhwa/" + manhwa.id, {url: inLink.value, chapter: manhwa.chapter, key: key.value}).subscribe(
        data => {
          this.loadMedias();
          div.removeChild(inLink);
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  generateLink(manhwa:Manhwa){
    if(manhwa.link != null ){
      return manhwa.link.replace("xxx", manhwa.chapter + "");
    }
    else{
      return manhwa.link;
    }
  }

  searchMedia(){
    var div = document.getElementById("txtSearch") as HTMLInputElement;
    var media = document.getElementById("filterMedia") as HTMLSelectElement;
    this.filteredManhwa = new Array<Manhwa>();
    this.filteredAnime = new Array<Anime>();
    this.filteredSeries = new Array<Series>();
    switch(media.value){
      case "anime":
        this.searchAnimes(div);
        break;
      case "series":
        this.searchSeries(div);
        break;
      case "manwha":
        this.searchManhwas(div);
        break;
      default:
        this.searchAnimes(div);
        this.searchManhwas(div);
        this.searchSeries(div);
        break;
    }
  }

  searchManhwas(div){
    for(var i = 0; i < this.manhwas?.length; i++){
      if(this.manhwas[i]?.name.toLowerCase().indexOf(div.value.toLowerCase()) != -1){
        this.filteredManhwa.push(this.manhwas[i]);
      }
    }
  }

  searchAnimes(div){
    
    for(var i = 0; i < this.animes?.length; i++){
      if(this.animes[i]?.name.toLowerCase().indexOf(div.value.toLowerCase()) != -1){
        this.filteredAnime.push(this.animes[i]);
      }
    }
  }

  searchSeries(div){
    
    for(var i = 0; i < this.series?.length; i++){
      if(this.series[i]?.name.toLowerCase().indexOf(div.value.toLowerCase()) != -1){
        this.filteredSeries.push(this.series[i]);
      }
    }
  }

  isSearching(){
    var div = document.getElementById("txtSearch") as HTMLInputElement;
    return (this.isFiltered || div.value.length > 0);
  }

  countChapters(){
    if(this.chapters == 0){
      this.manhwas?.forEach(manhwa => {
        this.chapters = this.chapters + manhwa.chapter;
      });
    }
    return this.chapters;
  }

  showSeason(){
    var media = document.getElementById("selectMedia") as HTMLSelectElement;
    this.needsSeason = (media.value == "anime" || media.value == "serie")
  }

  filterMedia(){
    var media = document.getElementById("filterMedia") as HTMLSelectElement;
    if(media.value != "all"){
      this.isFiltered = true;
    }
    else{
      this.isFiltered = false;
    }
  }
}
