<div id="gamingMain" class="row">
    <div class="col card">
        <div class="card-body">
            <h2 class="card-title">Gaming</h2>
            <p class="text-left">
                In meiner Freizeit interessiere ich mich sehr für Videospiele verschiedener Art.
            </p>
            <ul class="text-left">
                <li>Genres: Survival, Automation, Basebuilding, Simulation, Strategy</li>
                <li>Nebenbei streame ich auch auf Twitch, aber nur wenn ich Lust habe</li>
                <li>Ich möchte anfangen, meine Streams bearbeitet auch auf YouTube hochzuladen</li>
                <li>Am besten erreicht man mich über Steam oder Discord</li>
                <li>Bei manchen Spielen betreibe ich regelmässig Server, fragt einfach an wenn es euch interessiert</li>
            </ul>
            <p class="text-left">
                Wenn ihr mit mir spielen wollt, sendet einfach eine Freundschaftsanfrage auf Discord oder Steam und schreibt mich an.
                Ich werde sicher nicht mit allen spielen können/wollen, aber bin generell offen dafür, neue Leute kennenzulernen.
            </p>
        </div>
    </div>
    <div class="col-3">
        <div>
            <div class="row">
                <div class="col">
                    <a href="https://steamcommunity.com/id/finnchen/" target="_blank">
                        <img src="../../assets/img/steam.png" alt="Steam logo" width="100%">
                    </a>
                </div>
                <div class="col">
                    <a href="https://discord.gg/sEheURJTnS" target="_blank">
                        <img src="../../assets/img/discord.png" alt="Discord logo" width="100%">
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <a href="https://www.youtube.com/@finnchen123" target="_blank">
                        <img src="../../assets/img/youtube.png" alt="YouTube logo" width="100%">
                    </a>
                </div>
                <div class="col">
                    <a href="https://www.twitch.tv/finnchen123" target="_blank">
                        <img src="../../assets/img/twitch.png" alt="Twitch logo" width="100%">
                    </a>
                </div>
            </div>
        </div>
    </div>

</div>