import { Component } from '@angular/core';

@Component({
  selector: 'app-gaming',

  templateUrl: './gaming.component.html',
  styleUrl: './gaming.component.css'
})
export class GamingComponent {

}
