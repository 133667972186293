<div class="wrapper">
    <div class="placeholder placeholder-left"></div>
    <div class="content">
        <h2>Diese Seite wurde erstellt von:</h2>
        <p>Nicola Sovic</p>
        <p>Bielstrasse 39, 3250 Lyss, CH</p>
        <p>finnchennicola&#64;gmail.com</p>
        <p>+41 77 424 55 72</p>
        <h3>Hinweis</h3>
        <p>Es werden nur geschäftliche Anrufe und Mails beantwortet. Jeglicher Spam wird ignoriert und geblockt.</p>
    </div>
    <div class="placeholder placeholder-right"></div>
</div>

