<div id="aboutMain" class="row">
    <div class="col-3">
        <img src="../../assets/img/enfield.png" alt="Picture of my bike" width="100%">
    </div>
    <div class="col card">
        <div class="card-body">
            <h2 class="card-title">Über mich</h2>
            <p class="text-left">
                Nachfolgend ein paar Informationen über mich:
            </p>
            <ul class="text-left">
                <li>Name: Nicola Sovic</li>
                <li>Wohnsitz: Lyss (CH)</li>
                <li>Geboren: 04.10.1998 in Winterthur (CH)</li>
                <li>Staatsangehörigkeit: Schweiz (CH)</li>
                <li>Beruf: Ausbildner ICT-Fachmann/Fachfrau</li>
                <li>Hobbys: Videospiele, Programmieren, Motorrad fahren</li>
                <li>Lieblingsspruch: <i>Errare humanum est, sed in errare perseverare diabolicum</i></li>
            </ul>
        </div>
    </div>
</div>