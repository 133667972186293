<div class="wrapper">
    <div class="placeholder placeholder-left"></div>
    <div class="content">
        <h2>Datenschutzerklärung</h2>
                        <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
                        <br>
                        <p>Nicola Sovic<br>3250 Lyss</p>
                        <p>E-Mail: finnchennicola&#64;gmail.com<br>WebSite: http://www.nicolasovic.ch/ und in der Domain vorliegende Unterseiten</p>
                        <p>&nbsp;</p>
                        <h2>Allgemeiner Hinweis</h2>
                        <p>
                            Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG<!--DSG-->) 
                            hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. 
                            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und 
                            entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                        </p>
                        <p>
                            In Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich vor fremden Zugriffen, 
                            Verlusten, Missbrauch oder vor Fälschung zu schützen.
                        </p>
                        <p>
                            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. 
                            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                        </p>
                        <p>
                            Durch die Nutzung dieser Website erklären Sie sich mit der Erhebung, Verarbeitung und Nutzung von Daten gemäss der nachfolgenden Beschreibung einverstanden. 
                            Diese Website kann grundsätzlich ohne Registrierung besucht werden. Dabei werden Daten wie beispielsweise aufgerufene Seiten bzw. Namen der abgerufenen Datei, 
                            Datum und Uhrzeit zu statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden. Personenbezogene Daten, 
                            insbesondere Name, Adresse oder E-Mail-Adresse werden soweit möglich auf freiwilliger Basis erhoben. 
                            Ohne Ihre Einwilligung erfolgt keine Weitergabe der Daten an Dritte.
                            <!-- Edit Umfrage -->
                            <br>
                            Ausnahme hierbei bietet die Unterseite "Umfrage Erneuerbare Energien", welche alle eingegebenen Daten speichert und zur Auswertung und Präsentation verwendet werden.
                            Hierbei handelt es sich aber dennoch um anonymisierte Daten, bei denen kein Rückschluss auf Sie als Person gefunden werden kann. Eine Löschung der eingegeben Daten ist
                            aufgrund der fehlenden Nachvollziehbarkeit nicht möglich.
                        </p>
                        <p>&nbsp;</p>
                        <h2>Datenschutzerklärung für Cookies</h2>
                            <p>
                                Diese Website verwendet Cookies. Das sind kleine Textdateien, die es möglich machen, auf dem Endgerät des Nutzers spezifische, 
                                auf den Nutzer bezogene Informationen zu speichern, während er die Website nutzt. Cookies ermöglichen es, insbesondere Nutzungshäufigkeit 
                                und Nutzeranzahl der Seiten zu ermitteln, Verhaltensweisen der Seitennutzung zu analysieren, aber auch unser Angebot kundenfreundlicher zu gestalten. 
                                <!--Allg.Cookies-->Cookies bleiben über das Ende einer Browser-Sitzung gespeichert und können bei einem erneuten Seitenbesuch wieder aufgerufen werden. 
                                Wenn Sie das nicht wünschen, sollten Sie Ihren Internetbrowser so einstellen, dass er die Annahme von Cookies verweigert.
                            </p> 
                            <p>
                                Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, 
                                vor allem im Fall des Trackings, über die US-amerikanische Seite 
                                <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">http://www.aboutads.info/choices/</a> 
                                oder die EU-Seite <a href="http://www.youronlinechoices.com/" target="_blank" rel="noopener">http://www.youronlinechoices.com/</a> erklärt werden. 
                                Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden. 
                                Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden können.
                            </p> 
                            <p>&nbsp;</p>
                            <h2>Allgemeiner Haftungsausschluss</h2> 
                            <p>
                                Alle Angaben unseres Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, unser Informationsangebot aktuell, 
                                inhaltlich richtig und vollständig anzubieten. Trotzdem kann das Auftreten von Fehlern nicht völlig ausgeschlossen werden, 
                                womit wir keine Garantie für Vollständigkeit, Richtigkeit und Aktualität von Informationen auch journalistisch-redaktioneller Art übernehmen 
                                können. Haftungsansprüche aus Schäden materieller oder ideeller Art, die durch die Nutzung der angebotenen Informationen verursacht wurden, 
                                sind ausgeschlossen, sofern kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
                            </p>
                            <p>
                                Der Herausgeber kann nach eigenem Ermessen und ohne Ankündigung Texte verändern oder löschen und ist nicht verpflichtet, 
                                Inhalte dieser Website zu aktualisieren. Die Benutzung bzw. der Zugang zu dieser Website geschieht auf eigene Gefahr des Besuchers. 
                                Der Herausgeber, seine Auftraggeber oder Partner sind nicht verantwortlich für Schäden, wie direkte, indirekte, zufällige, 
                                vorab konkret zu bestimmende oder Folgeschäden, die angeblich durch den Besuch dieser Website entstanden sind und übernehmen hierfür 
                                folglich keine Haftung.
                            </p>
                            <p>
                                Der Herausgeber übernimmt ebenfalls keine Verantwortung und Haftung für die Inhalte und die Verfügbarkeit 
                                von Website Dritter, die über externe Links dieser Website erreichbar sind. Für den Inhalt der verlinkten Seiten sind ausschliesslich deren 
                                Betreiber verantwortlich. Der Herausgeber distanziert sich damit ausdrücklich von allen Inhalten Dritter, die möglicherweise 
                                straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstossen.
                            </p>
                            <p>&nbsp;</p>
                            <h2>Änderungen</h2>
                            <p>
                                Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. 
                                Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, 
                                werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail oder auf andere geeignete Weise informieren.
                            </p>
                            <p>&nbsp;</p>
                            <h2>Fragen an den Datenschutzbeauftragten</h2> 
                            <p>
                                Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für 
                                den Datenschutz zu Beginn der Datenschutzerklärung aufgeführten, verantwortlichen Person in unserer Organisation.
                            </p>
                            <p>&nbsp;</p>
                            <p>Neuenegg, 04.04.2020
                            <!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall unter Kostenfolge geahndet wird.-->
                            <br>Quelle: <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener">SwissAnwalt</a></p>
                            <!--Bitte beachten Sie die AGB von SwissAnwalt betreffend allfällig anfallenden Kosten bei Weglassen der Quelle!-->
    </div>
    <div class="placeholder placeholder-right"></div>
</div>

