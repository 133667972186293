<div id="workMain" class="row">
    <div class="col-3">
        <img src="../../assets/img/picture.jpg" alt="Picture of me" width="100%">
    </div>
    <div class="col card">
        <div class="card-body">
            <h2 class="card-title">Arbeit</h2>
            <p class="text-left">
                Derzeit arbeite ich bei der Rafisa Informatik GmbH als Ausbildner ICT-Fachmann/Fachfrau.
            </p>
            <ul class="text-left">
                <li>Stellensuche: Ich bin derzeit <b>nicht</b> auf Stellensuche</li>
                <li>Am besten erreicht man mich für Themen zur Arbeit über <a href="https://ch.linkedin.com/in/nicola-sovic-907911168" target="_blank">LinkedIn</a></li>
                <li>Je nach Kapazität bin ich ebenfalls als Freelancer tätig. Am besten mal auf <a href="https://de.fiverr.com/finnchen123" target="_blank">Fiverr</a> meine Gigs anschauen</li>
                <li>Ich habe derzeit die folgenden Abschlüsse/Zertifikate:</li>
                <ul>
                    <li>Informatiker EFZ, abgeschlossen 08.2022, läuft ab: Nie</li>
                    <li>ISC2 CC, abgeschlossen 12.2022, läuft ab: 12.2025</li>
                </ul>
            </ul>
        </div>
    </div>
</div>