<footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top bg-light">
    <div class="col-md-4 d-flex align-items-center">
        <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
            <svg class="bi" width="30" height="24">
                <use xlink:href="#bootstrap"></use>
            </svg>
        </a>
        <span class="text-muted">© 2024 Nicola Sovic</span>
    </div>

    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
        <li class="ms-3">
            <a href="/datenschutz">Datenschutz</a>
        </li>
        <li class="ms-3">
            <a href="/impressum">Impressum</a>
        </li>
        <li class="ms-3">
            Zuletzt aktualisiert: 24.08.2024
        </li>
    </ul>
</footer>