
<div>
    <input type="text" name="txtName" id="txtName" placeholder="Name">
    <input type="text" name="txtLink" id="txtLink" placeholder="Link">
    <input type="number" name="numChapter" id="numChapter" placeholder="Chapter">
    <input type="number" name="numSeason" id="numSeason" placeholder="Season" *ngIf="needsSeason">
    <select name="selectMedia" id="selectMedia" (change)="showSeason()">
        <option value="manhwa">Manhwa</option>
        <option value="anime">Anime</option>
        <option value="serie">Serie</option>
    </select>
    <button (click)="addMedia()">Add</button>
</div>

<div>
    <input type="text" name="txtSearch" id="txtSearch" (input)="searchMedia()" placeholder="Search...">
    <input type="text" name="txtKey" id="txtKey" placeholder="Key">
    <select name="filterMedia" id="filterMedia" (change)="filterMedia()">
        <option value="all">All</option>
        <option value="manhwa">Manhwa</option>
        <option value="anime">Anime</option>
        <option value="serie">Serie</option>
    </select>
</div>

<div class="stats">
    <span>Total Manhwas: {{ manhwas?.length }} </span>
    <span>Total Chapters: {{ countChapters() }} </span>
</div>

<div class="mediaList" *ngIf="!isSearching()">
    <div *ngFor="let manhwa of manhwas" class="media manhwa">
        <h2>{{manhwa.name}}</h2>
        <div [id]="'divManhwa' + '' + manhwa.id">
            <a href="{{generateLink(manhwa)}}" target="_blank" [id]="'linkManhwa' + '' + manhwa.id">LINK</a>
            <button (click)="editLink(manhwa)" [id]="'btnEdit' + '' + manhwa.id">Edit</button>
        </div>
        <span>Current: {{ manhwa.chapter }}</span>
        <span>Updated: {{ manhwa.lastUpdated }}</span>
        <div>
            <input type="number" placeholder="Anzahl" [id]="'inCount' + '' + manhwa.id">
            <button (click)="changeCount(manhwa, +1)">Increase</button>
            <button (click)="changeCount(manhwa, -1)">Decrease</button>
        </div>
    </div>
</div>

<div class="mediaList" *ngIf="!isSearching()">
    <div *ngFor="let anime of animes" class="media anime">
        <h2>{{anime.name}}</h2>
        <span>Current: S{{ anime.season }}E{{anime.episode}}</span>
        <span>Updated: {{ anime.lastUpdated }}</span>
        <div>
            <input type="number" placeholder="Season" [id]="'inSeasonAnime' + '' + anime.id">
            <button (click)="changeSeasonAnime(anime, +1)">Increase</button>
            <button (click)="changeSeasonAnime(anime, -1)">Decrease</button>
        </div>
        <div>
            <input type="number" placeholder="Episode" [id]="'inEpisodeAnime' + '' + anime.id">
            <button (click)="changeEpisodeAnime(anime, +1)">Increase</button>
            <button (click)="changeEpisodeAnime(anime, -1)">Decrease</button>
        </div>
    </div>
</div>

<div class="mediaList" *ngIf="!isSearching()">
    <div *ngFor="let serie of series" class="media serie">
        <h2>{{serie.name}}</h2>
        <span>Current: S{{ serie.season }}E{{serie.episode}}</span>
        <span>Updated: {{ serie.lastUpdated }}</span>
        <div>
            <input type="number" placeholder="Season" [id]="'inSeasonSeries' + '' + serie.id">
            <button (click)="changeSeasonSeries(serie, +1)">Increase</button>
            <button (click)="changeSeasonSeries(serie, -1)">Decrease</button>
        </div>
        <div>
            <input type="number" placeholder="Episode" [id]="'inEpisodeSeries' + '' + serie.id">
            <button (click)="changeEpisodeSeries(serie, +1)">Increase</button>
            <button (click)="changeEpisodeSeries(serie, -1)">Decrease</button>
        </div>
    </div>
</div>

<div class="mediaList" *ngIf="isSearching()">
    <div *ngFor="let manhwa of filteredManhwa" class="media manhwa">
        <h2>{{manhwa.name}}</h2>
        <div [id]="'divManhwa' + '' + manhwa.id">
            <a href="{{generateLink(manhwa)}}" target="_blank" [id]="'linkManhwa' + '' + manhwa.id">LINK</a>
            <button (click)="editLink(manhwa)" [id]="'btnEdit' + '' + manhwa.id">Edit</button>
        </div>
        <span>Current: {{ manhwa.chapter }}</span>
        <span>Updated: {{ manhwa.lastUpdated }}</span>
        <div>
            <input type="number" placeholder="Anzahl" [id]="'inCount' + '' + manhwa.id">
            <button (click)="changeCount(manhwa, +1)">Increase</button>
            <button (click)="changeCount(manhwa, -1)">Decrease</button>
        </div>
    </div>
</div>

<div class="mediaList" *ngIf="isSearching()">
    <div *ngFor="let anime of filteredAnime" class="media anime">
        <h2>{{anime.name}}</h2>
        <span>Current: S{{ anime.season }}E{{anime.episode}}</span>
        <span>Updated: {{ anime.lastUpdated }}</span>
        <div>
            <input type="number" placeholder="Season" [id]="'inSeasonAnime' + '' + anime.id">
            <button (click)="changeSeasonAnime(anime, +1)">Increase</button>
            <button (click)="changeSeasonAnime(anime, -1)">Decrease</button>
        </div>
        <div>
            <input type="number" placeholder="Episode" [id]="'inEpisodeAnime' + '' + anime.id">
            <button (click)="changeEpisodeAnime(anime, +1)">Increase</button>
            <button (click)="changeEpisodeAnime(anime, -1)">Decrease</button>
        </div>
    </div>
</div>

<div class="mediaList" *ngIf="isSearching()">
    <div *ngFor="let serie of filteredSeries" class="media serie">
        <h2>{{serie.name}}</h2>
        <span>Current: S{{ serie.season }}E{{ serie.episode }}</span>
        <span>Updated: {{ serie.lastUpdated }}</span>
        <div>
            <input type="number" placeholder="Season" [id]="'inSeasonSeries' + '' + serie.id">
            <button (click)="changeSeasonSeries(serie, +1)">Increase</button>
            <button (click)="changeSeasonSeries(serie, -1)">Decrease</button>
        </div>
        <div>
            <input type="number" placeholder="Episode" [id]="'inEpisodeSeries' + '' + serie.id">
            <button (click)="changeEpisodeSeries(serie, +1)">Increase</button>
            <button (click)="changeEpisodeSeries(serie, -1)">Decrease</button>
        </div>
    </div>
</div>